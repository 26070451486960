// src/components/LandingPage.js
import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar';
import Carousel from './Carousel';
import Screens from './Screens';
import Footer from './Footer';
import googlePlayStoreBadge1 from '../assets/Google Play Badge/googlePlayStoreBadge1.svg';
import appstore from '../assets/Google Play Badge/appstore.png';
import './LandingPage.css';

const LandingPage = () => {

  const [scrollDirection, setScrollDirection] = useState('static'); // Start as static
  const [hasScrolled, setHasScrolled] = useState(false); // Track if animation has occurred
  const lastScrollY = useRef(window.scrollY);

  const handleScroll = () => {
    if (hasScrolled) return; // Exit if animation has already occurred

    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('slideUp'); // Trigger slide-up animation
      setHasScrolled(true); // Prevent further animations
    } else if (currentScrollY < lastScrollY.current) {
      setScrollDirection('slideDown'); // Trigger slide-down animation
      setHasScrolled(true); // Prevent further animations
    }

    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]); // Dependency ensures effect re-runs only if `hasScrolled` changes


  const handleGooglePlayClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.app.unstag';
  };

  const handleAppStoreClick = () => {
    window.location.href = 'https://apps.apple.com/in/app/unstag-anonymous-campus-app/id6737087170';
  };

  return (
    <>
    <div>
    <Navbar />
    <div className="landing-page">
      <header className="header">
        <h1 className={`heading1 animated ${scrollDirection}`}>Unleash your thoughts, </h1>
        <h1 className={`heading2 animated ${scrollDirection} delay-1s`}>stay unseen.</h1>
        <h3 className={`heading3 animated ${scrollDirection} delay-1s`}>Get the app</h3>
        <div className= "storeButtons">
          <img src={googlePlayStoreBadge1} onClick={handleGooglePlayClick} alt="Download" className="playstore-button" />
          <img src={appstore} onClick={handleAppStoreClick} alt="Download" className="appstore-button" />
        </div>
      </header>

      <div className='header2'>
        <div className='card'>
        <h2 className={`title animated ${scrollDirection} delay-1s`}>Stay Safe !</h2>
        <p className={`description animated ${scrollDirection} delay-2s`}>Don't be too quick to give out all information.</p>
        </div>
        <div className='card'>
          <h2 className={`title animated ${scrollDirection} delay-1s`}>Play it cool.</h2>
          <p className={`description animated ${scrollDirection} delay-2s`}>Respect others and treat them as you would like to be treated.</p>
        </div>
      </div>

      <section className="screens-section">
        <Screens />
      </section>


        <div className="its-all-about">
          <p className={`animated ${scrollDirection}`}>its all about ...</p>
        </div>

      <section className="carousel-section">
        <Carousel />
      </section>

      <section className="footer-section">
        <Footer />
      </section>

    </div>
    </div>
    </>
  );
};

export default LandingPage;
