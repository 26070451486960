import React, { useState }  from 'react';
import logoN from '../assets/Logo Name/LogoandName.webp'
import './Navbar.css';

  
const Navbar = () => {

  const [isHovered, setIsHovered] = useState(false);

  const handleDownloadClick = async () => {
    if (navigator.userAgentData) {
      // Modern browsers with userAgentData support
      const uaData = await navigator.userAgentData.getHighEntropyValues(['platform']);
      const platform = uaData.platform.toLowerCase();
  
      if (platform.includes('android')) {
        // Redirect to Google Play Store
        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.unstag';
      } else if (platform.includes('ios') || platform.includes('mac')) {
        // Redirect to App Store
        window.location.href = 'https://apps.apple.com/in/app/unstag-anonymous-campus-app/id6737087170';
      } else if (platform.includes('windows')) {
        // Redirect to Google Play Store even on Windows
        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.unstag';
      } else if (platform.includes('linux')) {
        // Redirect to Google Play Store on Linux
        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.unstag';
      } else {
        // Fallback for unsupported platforms
        alert('Please open this link on your mobile device to download the app.');
      }
    } else {
      // Fallback for older browsers
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
      if (/android/i.test(userAgent)) {
        // Android
        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.unstag';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // iOS
        window.location.href = 'https://apps.apple.com/in/app/unstag-anonymous-campus-app/id6737087170';
      } else if (/Win/i.test(userAgent)) {
        // Redirect to Play Store even on Windows
        window.location.href = 'https://play.google.com/store/apps/details?id=com.app.unstag';
      } else if (/Mac/i.test(userAgent)) {
        // Redirect to App Store on macOS
        window.location.href = 'https://apps.apple.com/in/app/unstag-anonymous-campus-app/id6737087170';
      } else {
        // General fallback
        alert('Please open this link on your mobile device to download the app.');
      }
    }
  };

   // Functions to handle button clicks
   const handleGooglePlayClick = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.app.unstag',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const handleAppStoreClick = () => {
    window.open(
      'https://apps.apple.com/in/app/unstag-anonymous-campus-app/id6737087170',
      '_blank',
      'noopener,noreferrer'
    );
  };
  
    const [isMobile, setIsMobile] = useState(false);

    const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
    };  
 

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img className="logoN" src={logoN} alt='logo'></img>
      </div>
      <div className={`nav-links ${isMobile ? 'mobile' : ''}`}>
        <a href="#products">Our Products</a>
        <a href="#data-safety">Data Safety</a>
        <div
          className="dropdown-container"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <button onClick={handleDownloadClick} className="download-button">Download</button>
          {isHovered && (
            <div className="dropdown-menu">
              <button onClick={handleGooglePlayClick}>Download for Android</button>
              <button onClick={handleAppStoreClick}>Download for iOS</button>
            </div>
          )}
        </div>
      </div>
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      
    </nav>
  );
};


export default Navbar;
